<template>
  <figure class="highlight">
    <b-spinner label="Loading..." v-if="loading"></b-spinner>

    <b-container
      class="mt-6"
      style="padding-top: 2rem"
      fluid
      v-if="!loading && loggedInUser.isAdmin != 0"
    >
      <h3 class="py-2">
        <span class="text-capitalize">Dealer Users</span>
      </h3>

      <table class="car-options mt-4">
        <tr>
          <th style="width: 12%">Email Address *</th>
          <td>:</td>
          <td style="width: 37%">
            <b-form-input
              placeholder="Email Address"
              v-model="selecteduser.email"
            ></b-form-input>
          </td>

          <th style="width: 12%">Full Name *</th>
          <td>:</td>
          <td style="width: 37%">
            <b-form-input
              placeholder="Full Name"
              v-model="selecteduser.fullName"
            ></b-form-input>
          </td>
        </tr>

        <tr>
          <th>Phone No *</th>
          <td>:</td>
          <td>
            <b-form-input
              placeholder="Phone Number"
              v-model="selecteduser.phoneNo"
            ></b-form-input>
          </td>
          <th>Primary Duty *</th>
          <td>:</td>
          <td>
            <select
              class="mr-sm-2 form-control"
              v-model="selecteduser.primaryDuty"
            >
              <option></option>
              <option value="0">Parts</option>
              <option value="1">Motor Vehicle</option>
              <option value="2">Warranty</option>
            </select>
          </td>
        </tr>
        <tr>
          <th>Location</th>
          <td>:</td>
          <td>
            <select
              class="mr-sm-2 form-control"
              v-model="selecteduser.location"
            >
              <option
                v-for="location in locations"
                :key="location.LocationName"
                :value="location.DealerLocationID"
              >
                {{ location.LocationName }}
              </option>
            </select>
          </td>
          <td colspan="3">
            <table class="col-md-12">
              <tr>
                <td colspan="3">
                  <label>
                    <input type="checkbox" v-model="selecteduser.canOrder" />
                    Can Order
                  </label>
                </td>
                <td colspan="2">
                  <label>
                    <input type="checkbox" v-model="selecteduser.isAdmin" /> Is
                    Admin
                  </label>
                </td>
                <td colspan="3">
                  <label>
                    <input type="checkbox" v-model="selecteduser.isActive" /> Is
                    Active
                  </label>
                </td>
                <td colspan="3">
                  <label>
                    <input
                      type="checkbox"
                      v-model="selecteduser.isWarrantyAdmin"
                    />
                    Is Warranty Admin
                  </label>
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <th style="width: 12%">Password *</th>
          <td>:</td>
          <td style="width: 37%">
            <b-form-input
              type="password"
              placeholder="Password"
              v-model="selecteduser.pwd"
            ></b-form-input>
          </td>

          <th style="width: 12%">Confirm Password *</th>
          <td>:</td>
          <td style="width: 37%">
            <b-form-input
              type="password"
              placeholder="Confirm Password"
              v-model="selecteduser.confirmpwd"
            ></b-form-input>
          </td>
        </tr>
      </table>

      <b-button variant="primary" class="mt-3" @click="addNewUser"
        >Add New User</b-button
      >

      <div class="orders">
        <div class="p-5">
          <b-nav-form>
            <b-form-input
              class="mr-sm-2"
              placeholder="Filter"
              v-model="filter"
            ></b-form-input>
            <b-button
              size="sm"
              class="my-2 my-sm-0"
              v-if="filter"
              @click="filter = ''"
              >Clear</b-button
            >
          </b-nav-form>
          <b-table
            show-empty
            small
            stacked="md"
            :items="allUsers"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            :filter="filter"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            class="mt-3"
          >
            <template #cell(ActiveFlag)="row">
              <span
                :class="
                  row.item.ActiveFlag == 'Active'
                    ? 'text-success'
                    : 'text-danger'
                "
              >
                {{ row.item.ActiveFlag == "Active" ? "Active" : "Inactive" }}
              </span>
            </template>
            <template #cell(actions)="row">
              <b-button
                :variant="
                  row.item.ActiveFlag == 'Active' ? 'danger' : 'success'
                "
                @click="toggleUserStatus(row.item)"
              >
                {{
                  row.item.ActiveFlag == "Active" ? "Deactivate" : "Activate"
                }}
              </b-button>
            </template>
          </b-table>

          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            size="sm"
            class="my-pagination"
          ></b-pagination>
        </div>
      </div>
    </b-container>
  </figure>
</template>

<script>
import VehicleService from "../service/vehicle";
import { getData } from "../utils/storage";

export default {
  data() {
    return {
      fields: [
        { key: "Serial_No", label: "", sortable: true },
        { key: "EmailAddress", label: "Email Address", sortable: true },
        { key: "FullName", label: "Full Name", sortable: true },
        { key: "ActiveFlag", label: "Status", sortable: true },
        { key: "actions", label: "Actions" }
      ],
      currentPage: 1,
      perPage: 10,
      totalRows: 1,
      sortBy: "",
      sortDesc: false,
      filter: null,
      allUsers: [],
      loading: false,
      selecteduser: {},
      locations: [],
      customerBalance: 0,
      negativeBalance: true,
      loggedInUser: {}
    };
  },
  created() {
    const loggedInUser = getData("userInfo");
    console.log("loggedInUser: ", loggedInUser);
    this.loggedInUser = loggedInUser;
    if (loggedInUser.isAdmin == 0) {
      alert("You do not have permissions to this page");
      return;
    } else {
      this.dealerid = loggedInUser.DealerID;
      this.getExistingUsers();
      this.getDealerLocations();
    }
  },
  methods: {
    async getExistingUsers() {
      this.loading = true;
      const { data } = await VehicleService.getDetailedDealerUsers(
        this.dealerid
      );
      this.loading = false;
      this.allUsers = data.Data;
      this.totalRows = data.Data.length;
    },
    async getDealerLocations() {
      try {
        const { data } = await VehicleService.getDealerLocations(this.dealerid);
        this.locations = data.data;
      } catch (error) {
        console.error("Error fetching locations:", error);
      }
    },
    async addNewUser() {
      if (
        !this.selecteduser.email ||
        !this.selecteduser.fullName ||
        !this.selecteduser.pwd ||
        !this.selecteduser.confirmpwd
      ) {
        alert("All required fields must be filled!");
        return;
      }
      if (this.selecteduser.pwd !== this.selecteduser.confirmpwd) {
        alert("Passwords do not match!");
        return;
      }
      try {
        this.selecteduser.dealerID = this.dealerid;
        await VehicleService.addDealerUser(this.selecteduser);
        alert("User added successfully!");
        this.selecteduser = {};
        this.getExistingUsers();
      } catch (error) {
        alert("Error adding user: " + error.message);
      }
    },
    async toggleUserStatus(user) {
      try {
        const active = !(user.ActiveFlag == "Active");
        const userInfo = {
          email: user.EmailAddress,
          isActive: active
        };
        await VehicleService.updateUserStatus(userInfo);
        user.ActiveFlag = active == 1 ? "Active" : "Inactive";
      } catch (error) {
        console.error("Error updating user status:", error);
      }
    }
  }
};
</script>

<style scoped>
.mt-6 {
  margin-top: 4rem !important;
}
</style>
